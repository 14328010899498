import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { AttachmentsDetailsError } from "~/typings/types";
import cn from "classnames";

import "./UploadFile.modules.scss";

const removeNumberByIndex = (index: number, setFileid: Dispatch<SetStateAction<number[]>>) => {
  setFileid(prev => {
    const updatedNumbers = [...prev];
    updatedNumbers.splice(index, 1);
    return updatedNumbers;
  });
};

const ContentUploadButton = (
  <>
    <span className="upload-button-icon" /> Загрузить
  </>
);
const antIcon = <LoadingOutlined style={{ fontSize: 18, color: "#3d935e" }} spin />;

type Props = {
  id: string;
  count?: number;
  uploadedFiles: File[];
  setUploadedFiles: Dispatch<File[]>;
  isLoadingProps?: boolean;
  setFileid: Dispatch<SetStateAction<number[]>>;
  setError: Dispatch<AttachmentsDetailsError>;
  containerHeight?: number;
  setIsFirstLoadingFile: Dispatch<boolean>
};

export const UploadFile = ({
  id,
  count,
  uploadedFiles,
  setUploadedFiles,
  isLoadingProps,
  setFileid,
  setError,
  containerHeight,
  setIsFirstLoadingFile
}: Props) => {
  console.log(id, uploadedFiles)
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;

    if (newFiles && newFiles.length > 0) {
      const filesToUpload = [...uploadedFiles, ...Array.from(newFiles)];
      setUploadedFiles(filesToUpload);
      // Сбрасываем target.value - чтобы можно было повторно загружать тот же файл после его удаления
      event.target.value = "";
      setError(null);
    }
  };

  const handleRemoveFile = (file: File, index: number) => {
    if (isLoadingProps) {
      return;
    }
    const updatedFiles = uploadedFiles.filter(uploadedFile => uploadedFile !== file);
    setUploadedFiles(updatedFiles);
    removeNumberByIndex(index, setFileid);
    setError(null);
    setIsFirstLoadingFile(true)
  };

  const isDisabledUploudButton =
    uploadedFiles.length === count || (isLoadingProps && uploadedFiles.length === count);

  return (
    <div
      className="upload-container"
      style={{ ...(containerHeight && { height: containerHeight }) }}
    >
      <label
        htmlFor={`file-upload` + id}
        className={cn("upload-button", {
          "upload-button-disabled": isDisabledUploudButton
        })}
      >
        {isLoadingProps ? <Spin indicator={antIcon} /> : ContentUploadButton}
      </label>
      <input
        disabled={isDisabledUploudButton}
        type="file"
        id={`file-upload` + id}
        className="hidden"
        onChange={handleFileChange}
      />
      {uploadedFiles.map((file, index) => (
        <div className="file-info" key={index}>
          <span id="file-name">{file.name}</span>
          <button type="button" id="remove-file" onClick={() => handleRemoveFile(file, index)} />
        </div>
      ))}
    </div>
  );
};
