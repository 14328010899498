import { useEffect } from "react";
import { OrgTypeEnum } from "~/typings/types";
import {
  DefaultPage,
  Wizard,
  useAttachmentsDetails,
  useConfirmationCode
} from "~/pages/questionnaires/shared";
import {
  CompanyDetails,
  ContactDetails,
  PayformDetails,
  AccountDetails,
  PayformTechDetails,
  MarketplaceDetails,
  AdditionalDetails,
  AttachmentsDetails,
  ConfirmationCode
} from "./steps";
import { HELPER_FILE } from "~/pages/questionnaires/customer/api/apiUrls";

const COUNT_UPLOAD_LOGO_FILE = 1;
const COUNT_UPLOAD_PASSPORT_FILE = 2;
const COUNT_DOCUMENT_AGREEMENT1_FILE= 1;
const COUNT_DOCUMENT_AGREEMENT2_FILE = 1;

export const SelfEmployedStep = ({
  clientId,
  paymentMethod,
  educationalPlatform,
  queryPromocode,
  setOrgType,
  productType,
  ...sharedStepProps
}: any) => {
  useEffect(() => {
    setOrgType(OrgTypeEnum.SelfEmployed);
  }, []);

  const attachmentsDetails = useAttachmentsDetails({
    clientId,
    orgType: OrgTypeEnum.SelfEmployed,
    initialLogoFileId: sharedStepProps.initialValues.logo_file_id,
    initialPassportFileId: sharedStepProps.initialValues.passport_file_id,
    initialDocumentAgreement1FileId: sharedStepProps.initialValues.document_agreement1_file_id,
    initialDocumentAgreement2FileId: sharedStepProps.initialValues.document_agreement2_file_id,
    isAcceptPoliticsDefault: false,
    urlHelperFile: HELPER_FILE
  });

  const confirmationCode = useConfirmationCode({ clientId });

  return (
    <Wizard
      {...sharedStepProps}
      logoFileId={attachmentsDetails.logoFileId}
      passportFileId={attachmentsDetails.passportFileId}
      documentAgreement1FileId={attachmentsDetails.documentAgreement1FileId}
      documentAgreement2FileId={attachmentsDetails.documentAgreement2FileId}
      handleRequestAttachmentsDetails={attachmentsDetails.handleRequestAttachmentsDetails}
      isLoadingAttachmentsDetails={attachmentsDetails.isLoadingAttachmentsDetails}
      isErrorAttachmentsDetails={attachmentsDetails.isErrorAttachmentsDetails}
      isAcceptPolitics={attachmentsDetails.isAcceptPolitics}
      acceptPoliticsError={attachmentsDetails.acceptPoliticsError}
      setAcceptPoliticsError={attachmentsDetails.setAcceptPoliticsError}
      statusConfirmationCode={confirmationCode.statusConfirmationCode}
      handleGetRequestCode={confirmationCode.handleGetRequestCode}
      isLoadingGetRequestCode={confirmationCode.isLoading}
    >
      <DefaultPage />
      <AccountDetails />
      <CompanyDetails clientId={clientId} />
      <ContactDetails />
      <PayformDetails />
      <PayformTechDetails
        paymentMethod={paymentMethod}
        educationalPlatform={educationalPlatform}
        productType={productType}
        typeRequestCheckPayformName={sharedStepProps.typeRequestCheckPayformName}
        callbackCheckPayformName={sharedStepProps.callbackCheckPayformName}
      />
      <MarketplaceDetails />
      <AdditionalDetails queryPromocode={queryPromocode} />
      <AttachmentsDetails
        {...attachmentsDetails}
        COUNT_UPLOAD_LOGO_FILE={COUNT_UPLOAD_LOGO_FILE}
        COUNT_UPLOAD_PASSPORT_FILE={COUNT_UPLOAD_PASSPORT_FILE}
        COUNT_DOCUMENT_AGREEMENT1_FILE={COUNT_DOCUMENT_AGREEMENT1_FILE}
        COUNT_DOCUMENT_AGREEMENT2_FILE={COUNT_DOCUMENT_AGREEMENT2_FILE}
        orgType={OrgTypeEnum.SelfEmployed}
      />
      <ConfirmationCode
        {...confirmationCode}
        clientId={clientId}
        setIsRenderSuccessPage={sharedStepProps.setIsRenderSuccessPage}
        setIsRenderErrorPage={sharedStepProps.setIsRenderErrorPage}
        isLoadingGetRequestCode={confirmationCode.isLoading}
      />
    </Wizard>
  );
};
