import { ReactNode, useEffect, useRef, useState } from "react";
import cn from "classnames";

import styles from "./DescriptionCollapseField.module.scss";

interface DescriptionCollapseFieldProps {
  content: ReactNode;
  trigger: boolean;
}

export const DescriptionCollapseField = ({
  content,
  trigger,
}: DescriptionCollapseFieldProps) => {
  const [descriptionHeight, setDescriptionHeight] = useState(0);
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateHeight = () => {
      if (descriptionRef.current) {
        setDescriptionHeight(descriptionRef.current.scrollHeight);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      calculateHeight();
    });

    if (descriptionRef.current) {
      resizeObserver.observe(descriptionRef.current);
      calculateHeight();
    }

    return () => resizeObserver.disconnect();
  }, [content]);

  if (!content) {
    return null;
  }

  return (
    <div
      ref={descriptionRef}
      className={cn(styles.container, {
        [styles.expanded]: trigger,
        [styles.collapsed]: !trigger,
      })}
      style={{
        maxHeight: trigger ? `${descriptionHeight}px` : "0px",
      }}
    >
      {content}
    </div>
  );
};
