import styles from "./TitlePage.module.scss";

interface TitlePage {
  name: string;
  number: string;
  description?: string;
}

export const TitlePage = ({ name, number, description }: TitlePage) => (
  <div className={styles.container}>
    <p className={styles.number}>{number}</p>
    <div className={styles.containerTitle}>
      <h2 className={styles.name}>{name}</h2>
      {description && (
        <p className={styles.description}>{description}</p>
      )}
    </div>
  </div>
);
