import { useCallback, useEffect, useState } from "react";
import { clientApi, getTypeQuestionnaires } from "~/utils";
import { postDraftAttachmentsDetails as postDraftAttachmentsDetailsCustomer } from "~/pages/questionnaires/customer/api/apiMethods";
import { postDraftAttachmentsDetails as postDraftAttachmentsDetailsPartner } from "~/pages/questionnaires/partner/api/apiMethods";
import { AttachmentsDetailsError, ClientId, NamesOfFieldEnum, OrgTypeEnum } from "~/typings/types";

import { useUploadLogoFile, useUploadPassportFile } from ".";

const fetchFileById = (fileId: any, setFiles: any, setFileIds: any) => {
  const id = Array.isArray(fileId)
    ? fileId.length > 0 ? fileId[0] : null
    : fileId;

  if (id) {
    clientApi
      .get(`/api/questionnaire/v1/helper/file/?file_id=${id}`)
      .then(res => {
        setFiles((prev: any) => [
          ...prev,
          new File([], res.data.data.filename_original),
        ]);
        setFileIds((prev: any) => [...prev, res.data.data.file_id]);
      });
  }
};

// TODO: need refactor
// Функия для валидации которая приходит с бека, если приходит ошибки то показываем ошибку под Файламм
const getErrorAttachmentDetails = (dataError: any, setLogoError: any, setPassportError: any, setDocumentAgreement1Error: any, setDocumentAgreement2Error: any) => {
  if (!dataError || !Array.from(dataError) || !dataError.length) {
    return;
  }

  dataError.forEach((error: any) => {
    const fieldName = error.data.target;
    if (fieldName === NamesOfFieldEnum.LogoFileId || fieldName === NamesOfFieldEnum.PassportMainFileId) {
      setLogoError({
        has: 1,
        message: error.message
      });
    }
    if (fieldName === NamesOfFieldEnum.PassportFileId || fieldName === NamesOfFieldEnum.PassportRegistrationFileId) {
      setPassportError({
        has: 1,
        message: error.message
      });
    }
    if (fieldName === NamesOfFieldEnum.DocumentAgreement1FileId) {
      setDocumentAgreement1Error({
        has: 1,
        message: error.message
      });
    }
    if (fieldName === NamesOfFieldEnum.DocumentAgreement2FileId) {
      setDocumentAgreement2Error({
        has: 1,
        message: error.message
      });
    }
  });
};

type UseAttachmentsDetails = {
  clientId: ClientId;
  orgType: OrgTypeEnum;
  initialLogoFileId: number[];
  initialPassportFileId: number[];
  initialDocumentAgreement1FileId: number[];
  initialDocumentAgreement2FileId: number[];
  isAcceptPoliticsDefault?: boolean;
  urlHelperFile: string;
};

export const useAttachmentsDetails = ({
  clientId,
  orgType,
  initialLogoFileId,
  initialPassportFileId,
  initialDocumentAgreement1FileId,
  initialDocumentAgreement2FileId,
  urlHelperFile,
  isAcceptPoliticsDefault = true,
}: UseAttachmentsDetails) => {
  /** Запрашиваем сохранненые файлы ранее при маунте компонента и получаем id файлов */
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    Promise.allSettled([
      initialLogoFileId[0] &&
      clientApi
        .get(`/api/questionnaire/v1/helper/file/?file_id=${initialLogoFileId[0]}`)
        .then(res => {
          setLogoFiles(prev => [...prev, new File([], res.data.data.filename_original)]);
          setLogoFileId(prev => [...prev, res.data.data.file_id]);
        }),
      initialPassportFileId[0] &&
      clientApi
        .get(`/api/questionnaire/v1/helper/file/?file_id=${initialPassportFileId[0]}`)
        .then(res => {
          setPassportFiles(prev => [...prev, new File([], res.data.data.filename_original)]);
          setPassportFileId(prev => [...prev, res.data.data.file_id]);
        }),
      initialPassportFileId[1] &&
      clientApi
        .get(`/api/questionnaire/v1/helper/file/?file_id=${initialPassportFileId[1]}`)
        .then(res => {
          setPassportFiles(prev => [...prev, new File([], res.data.data.filename_original)]);
          setPassportFileId(prev => [...prev, res.data.data.file_id]);
        }),
      fetchFileById(
        initialDocumentAgreement1FileId,
        setDocumentAgreement1Files,
        setDocumentAgreement1FileId
      ),
      fetchFileById(
        initialDocumentAgreement2FileId,
        setDocumentAgreement2Files,
        setDocumentAgreement2FileId
      )
    ]).finally(() => setIsLoading(false));
  }, []);
  /** ------ end ------ */

  /** ------ Отправляем файл на сервер после загрузки <input type="file" /> ------ */
  const [logoFiles, setLogoFiles] = useState<File[]>([]);
  const [documentAgreement1Files, setDocumentAgreement1Files] = useState<File[]>([]);
  const [documentAgreement2Files, setDocumentAgreement2Files] = useState<File[]>([]);
  const [passportFiles, setPassportFiles] = useState<File[]>([]);
  const [logoError, setLogoError] = useState<AttachmentsDetailsError>(null);
  const [documentAgreement1Error, setDocumentAgreement1Error] = useState<AttachmentsDetailsError>(null);
  const [documentAgreement2Error, setDocumentAgreement2Error] = useState<AttachmentsDetailsError>(null);
  const [passportError, setPassportError] = useState<AttachmentsDetailsError>(null);
  const [isFirstLoadingFile, setIsFirstLoadingFile] = useState(false); // Флаг который изменятся когда происходит хоть одно с действием с файлами, удаление/добавление

  const isErrorAttachmentsDetails = Boolean(logoError) || Boolean(passportError) || Boolean(documentAgreement1Error) || Boolean(documentAgreement2Error);

  const {
    filesId: logoFileId,
    setFilesId: setLogoFileId,
    isLoading: isLoadingUploadLogoFile
  } = useUploadLogoFile({ logoFiles, setLogoError, setIsFirstLoadingFile, urlHelperFile });

  const {
    filesId: documentAgreement1FileId,
    setFilesId: setDocumentAgreement1FileId,
    isLoading: isLoadingUploadDocumentAgreement1File
  } = useUploadLogoFile({ logoFiles: documentAgreement1Files, setLogoError: setDocumentAgreement1Error, setIsFirstLoadingFile, urlHelperFile });

  const {
    filesId: documentAgreement2FileId,
    setFilesId: setDocumentAgreement2FileId,
    isLoading: isLoadingUploadDocumentAgreement2File
  } = useUploadLogoFile({ logoFiles: documentAgreement2Files, setLogoError: setDocumentAgreement2Error, setIsFirstLoadingFile, urlHelperFile });

  const {
    filesId: passportFileId,
    setFilesId: setPassportFileId,
    isLoading: isLoadingUploadPassportFile
  } = useUploadPassportFile({
    passportFiles,
    setPassportError,
    setIsFirstLoadingFile,
    urlHelperFile
  });
  /** ------ end ------ */

  /** ------ Сохраняем в черновик отправленные файла по id ------ */
  const [isLoadingAttachmentsDetails, setIsLoadingAttachmentsDetails] = useState(false);

  const isPartnerTypeQuestionnaires = getTypeQuestionnaires() === "partner";
  // Надо рефакторинг всего этого дела связанного с разделением анкет на партнеров и клиентов
  const keyFieldFile1 = isPartnerTypeQuestionnaires ? NamesOfFieldEnum.PassportMainFileId : NamesOfFieldEnum.LogoFileId;
  const keyFieldFile2 = isPartnerTypeQuestionnaires
    ? NamesOfFieldEnum.PassportRegistrationFileId
    : NamesOfFieldEnum.PassportFileId;

  const postDraftAttachmentsDetails = isPartnerTypeQuestionnaires
    ? postDraftAttachmentsDetailsPartner
    : postDraftAttachmentsDetailsCustomer;

  const handleRequestAttachmentsDetails = useCallback(
    async ({ orgType }: { orgType: OrgTypeEnum }) => {
      try {
        setIsLoadingAttachmentsDetails(true);
        const responsePostDraftAttachmentsDetails = await postDraftAttachmentsDetails({
          data: {
            id: clientId,
            is_presave_mode: false,
            fields: {
              ...(logoFileId?.length && {
                [keyFieldFile1]: isPartnerTypeQuestionnaires ? logoFileId[0] : logoFileId
              }),
              ...(passportFileId?.length && {
                [keyFieldFile2]: isPartnerTypeQuestionnaires ? passportFileId[0] : passportFileId
              }),
              ...(documentAgreement1FileId?.length && !isPartnerTypeQuestionnaires && {
                [NamesOfFieldEnum.DocumentAgreement1FileId]: documentAgreement1FileId[0]
              }),
              ...(documentAgreement2FileId?.length && !isPartnerTypeQuestionnaires && {
                [NamesOfFieldEnum.DocumentAgreement2FileId]: documentAgreement2FileId[0]
              }),
              [NamesOfFieldEnum.OrgType]: orgType,
              [NamesOfFieldEnum.IsOffertAccepted]: 1
            }
          }
        });
        setIsLoadingAttachmentsDetails(false);
        if (
          responsePostDraftAttachmentsDetails?.success === 0 &&
          responsePostDraftAttachmentsDetails?.errors &&
          responsePostDraftAttachmentsDetails.errors.length > 0
        ) {
          getErrorAttachmentDetails(
            responsePostDraftAttachmentsDetails.errors,
            setLogoError,
            setPassportError,
            setDocumentAgreement1Error,
            setDocumentAgreement2Error
          );
          return 0; // возвращаем статус ошибки валидации
        }
      } catch (error) {
        throw error;
      }
    },
    [logoFileId, passportFileId, documentAgreement1FileId, documentAgreement2FileId]
  );
  /** ------ end ------ */


  useEffect(() => {
    if (isFirstLoadingFile) {
      postDraftAttachmentsDetails({
        data: {
          id: clientId,
          is_presave_mode: true,
          fields: {
            [keyFieldFile1]: isPartnerTypeQuestionnaires ? logoFileId[0] : logoFileId,
            [keyFieldFile2]: isPartnerTypeQuestionnaires ? passportFileId[0] : passportFileId,
            [NamesOfFieldEnum.DocumentAgreement1FileId]: documentAgreement1FileId[0],
            [NamesOfFieldEnum.DocumentAgreement2FileId]: documentAgreement2FileId[0],
            [NamesOfFieldEnum.OrgType]: orgType,
            [NamesOfFieldEnum.IsOffertAccepted]: 1
          }
        }
      });
    }
  }, [logoFileId, passportFileId, documentAgreement1FileId, documentAgreement2FileId])

  const [isAcceptPolitics, setIsAcceptPolitics] = useState(isAcceptPoliticsDefault);
  const [acceptPoliticsError, setAcceptPoliticsError] = useState<AttachmentsDetailsError>(null);

  return {
    isLoadingMount: isLoading,
    isLoadingLogo: isLoadingUploadLogoFile,
    isLoadingPassport: isLoadingUploadPassportFile,
    isLoadingAttachmentsDetails,
    isErrorAttachmentsDetails,
    logoFileId,
    logoFiles,
    setLogoFileId,
    setLogoFiles,
    passportFileId,
    passportFiles,
    setPassportFileId,
    setPassportFiles,
    handleRequestAttachmentsDetails,
    logoError,
    passportError,
    setLogoError,
    setPassportError,
    isAcceptPolitics,
    setIsAcceptPolitics,
    acceptPoliticsError,
    setAcceptPoliticsError,
    setIsFirstLoadingFile,
    isLoadingDocumentAgreement1File: isLoadingUploadDocumentAgreement1File,
    isLoadingDocumentAgreement2File: isLoadingUploadDocumentAgreement2File,
    documentAgreement1FileId,
    documentAgreement2FileId,
    documentAgreement1Files,
    documentAgreement2Files,
    setDocumentAgreement1Files,
    setDocumentAgreement2Files,
    setDocumentAgreement1FileId,
    setDocumentAgreement2FileId,
    documentAgreement1Error,
    documentAgreement2Error,
    setDocumentAgreement1Error,
    setDocumentAgreement2Error,
  };
};
