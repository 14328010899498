import { Dispatch, SetStateAction } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Spin } from "antd";
import cn from "classnames";
import { TitlePage } from "~/components";
import {
  RulesFiles,
  FormField,
  Wizard,
  UploadFile,
  AcceptPolitics
} from "~/pages/questionnaires/shared";
import { AttachmentsDetailsError, NamesOfFieldEnum } from "~/typings/types";
import { configTitleFieldsOfIndividualEntrepreneur } from "../configTitleFields";

import "~/pages/questionnaires/shared/styles.css";
import styles from "~/pages/questionnaires/shared/Shared.module.scss";
import { useForm } from "react-final-form";

interface Props {
  isLoadingMount: boolean;
  isLoadingLogo: boolean;
  isLoadingPassport: boolean;
  logoFiles: File[];
  setLogoFileId: Dispatch<SetStateAction<number[]>>;
  setLogoFiles: Dispatch<File[]>;
  passportFiles: File[];
  setPassportFileId: Dispatch<SetStateAction<number[]>>;
  setPassportFiles: Dispatch<File[]>;
  logoError: AttachmentsDetailsError;
  passportError: AttachmentsDetailsError;
  setLogoError: Dispatch<AttachmentsDetailsError>;
  setPassportError: Dispatch<AttachmentsDetailsError>;
  COUNT_UPLOAD_LOGO_FILE?: number;
  COUNT_UPLOAD_PASSPORT_FILE?: number;
  isAcceptPolitics: boolean;
  setIsAcceptPolitics: Dispatch<SetStateAction<boolean>>;
  acceptPoliticsError: AttachmentsDetailsError;
  setAcceptPoliticsError: Dispatch<SetStateAction<AttachmentsDetailsError>>;
  setIsFirstLoadingFile: Dispatch<boolean>;
}

export const AttachmentsDetails = ({
  isLoadingMount,
  isLoadingLogo,
  isLoadingPassport,
  logoFiles,
  setLogoFileId,
  setLogoFiles,
  passportFiles,
  setPassportFileId,
  setPassportFiles,
  logoError,
  passportError,
  setLogoError,
  setPassportError,
  COUNT_UPLOAD_LOGO_FILE,
  COUNT_UPLOAD_PASSPORT_FILE,
  isAcceptPolitics,
  setIsAcceptPolitics,
  acceptPoliticsError,
  setAcceptPoliticsError,
  setIsFirstLoadingFile
}: Props) => {
  const values = useForm().getState().values;
  const isHidePayformDetailsStep = values?.[NamesOfFieldEnum.IsWantCreatePayform];
  const currentPage = isHidePayformDetailsStep ? "5" : "4";

  return (
    <Wizard.Page>
      <TitlePage name="Загрузите необходимые документы" number={currentPage} />
      <Divider style={{ margin: 0 }} />
      <div className={cn(styles.container, styles.containerUpload)}>
        {isLoadingMount ? (
          <div className={styles.containerLoader}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#3d935e" }} spin />} />
          </div>
        ) : (
          <>
            <FormField
              titleField={
                configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.PassportMainFileId]
              }
              name={NamesOfFieldEnum.PassportMainFileId}
              styleContainer={{ marginBottom: 24, minHeight: 150 }}
              renderError={logoError}
              required
            >
              <div>
                <UploadFile
                  id={NamesOfFieldEnum.PassportMainFileId}
                  uploadedFiles={logoFiles}
                  setUploadedFiles={setLogoFiles}
                  isLoadingProps={isLoadingLogo}
                  setFileid={setLogoFileId}
                  count={COUNT_UPLOAD_LOGO_FILE}
                  setError={setLogoError}
                  setIsFirstLoadingFile={setIsFirstLoadingFile}
                />
              </div>
            </FormField>

            <FormField
              titleField={
                configTitleFieldsOfIndividualEntrepreneur[
                NamesOfFieldEnum.PassportRegistrationFileId
                ]
              }
              name={NamesOfFieldEnum.PassportRegistrationFileId}
              required
              styleContainer={{ minHeight: 190 }}
              renderError={passportError}
            >
              <div>
                <UploadFile
                  id={NamesOfFieldEnum.PassportRegistrationFileId}
                  uploadedFiles={passportFiles}
                  setUploadedFiles={setPassportFiles}
                  isLoadingProps={isLoadingPassport}
                  setFileid={setPassportFileId}
                  count={COUNT_UPLOAD_PASSPORT_FILE}
                  setError={setPassportError}
                  setIsFirstLoadingFile={setIsFirstLoadingFile}
                />
              </div>
            </FormField>
          </>
        )}
      </div>
      <RulesFiles isPartnerTypeQuestionnaires />
      <Divider style={{ margin: 0 }} />
      <AcceptPolitics
        isAcceptPolitics={isAcceptPolitics}
        setIsAcceptPolitics={setIsAcceptPolitics}
        acceptPoliticsError={acceptPoliticsError}
        setAcceptPoliticsError={setAcceptPoliticsError}
      />
    </Wizard.Page>
  );
};
